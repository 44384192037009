import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaRegSmileWink } from 'react-icons/fa';

const PublicGoalProgressChart = ({ goals }) => {
    const calculateCompletionPercentage = (goal) => {
        const totalDays = goal.progress.length;
        const completedDays = goal.progress.filter(day => day.completed).length;
        return totalDays === 0 ? 0 : (completedDays / totalDays) * 100;
    };

    if (goals.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center mt-4 p-4 bg-white rounded-lg shadow-lg">
                <FaRegSmileWink className="text-6xl text-gray-400 mb-4" />
                <p className="text-gray-600">Explore our community's goals and get inspired!</p>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
            {goals.map((goal) => {
                const completionPercentage = calculateCompletionPercentage(goal);

                return (
                    <div key={goal._id} className="bg-white p-4 rounded-lg shadow-lg flex flex-col items-center">
                        <h2 className="text-xl font-bold mb-2">{goal.title}</h2>
                        <div style={{ width: 100, height: 100 }}>
                            <CircularProgressbarWithChildren
                                value={completionPercentage}
                                styles={buildStyles({
                                    pathColor: '#00ff00',
                                    trailColor: '#d6d6d6',
                                    textSize: '16px',
                                })}
                            >
                                <div style={{ marginTop: -5 }}>
                                    <strong>{`${Math.round(completionPercentage)}%`}</strong>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default PublicGoalProgressChart;
