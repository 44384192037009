import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import AdminDashboard from './components/admin/AdminDashboard';
import PublicDashboard from './components/PublicDashboard';
import GoalList from './components/GoalList';
import TransitionWrapper from './components/TransitionWrapper';
import ProtectedRoute from './components/ProtectedRoute';
import SharedProgress from './components/SharedProgress';
import VerifyEmail from './components/VerifyEmail';
import ResetPassword from './components/ResetPassword';
import NotFound from './components/NotFound';
import Profile from './components/Profile';
import CompleteProfile from './components/CompleteProfile';
import api from './api';
import { setToken, removeToken, getToken } from './auth';

const imagePath = `${process.env.PUBLIC_URL}/loosing_weight.jpg`;

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!getToken());
    const [currentStreak, setCurrentStreak] = useState(0);
    const [profileComplete, setProfileComplete] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStreak = async () => {
            try {
                const { data } = await api.get('/api/streak');
                setCurrentStreak(data.streak);
            } catch (error) {
                console.error('Error fetching streak:', error);
            }
        };

        if (isAuthenticated) {
            fetchStreak();
        }
    }, [isAuthenticated]);

    const handleLogin = (token, profileComplete, role) => {
        setToken(token);
        setIsAuthenticated(true);
        setProfileComplete(profileComplete);
        setUserRole(role);

        if (role === 'admin') {
            navigate('/admin/dashboard');
        } else if (profileComplete) {
            navigate('/dashboard');
        } else {
            navigate('/complete-profile');
        }
    };

    const handleProfileUpdate = () => {
        setProfileComplete(true);
        navigate('/dashboard');
    };

    const handleLogout = () => {
        removeToken();
        setIsAuthenticated(false);
        setProfileComplete(false);
        setUserRole(null);
        navigate('/login');
    };

    return (
        <TransitionWrapper>
            <Routes>
                {/* Public dashboard available to all users */}
                <Route path="/" element={<PublicDashboard image={imagePath} />} />

                {/* Auth-related routes */}
                <Route
                    path="/login"
                    element={
                        isAuthenticated ? (
                            userRole === 'admin' ? (
                                <Navigate to="/admin/dashboard" />
                            ) : profileComplete ? (
                                <Navigate to="/dashboard" />
                            ) : (
                                <Navigate to="/complete-profile" />
                            )
                        ) : (
                            <Login onLogin={handleLogin} />
                        )
                    }
                />
                <Route
                    path="/register"
                    element={
                        isAuthenticated ? (
                            profileComplete ? <Navigate to="/dashboard" /> : <Navigate to="/complete-profile" />
                        ) : (
                            <Register />
                        )
                    }
                />

                {/* Admin dashboard route */}
                <Route
                    path="/admin/dashboard"
                    element={
                        <ProtectedRoute>
                            <AdminDashboard onLogout={handleLogout} />
                        </ProtectedRoute>
                    }
                />

                {/* Protected routes */}
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute>
                            <Dashboard
                                currentStreak={currentStreak}
                                setCurrentStreak={setCurrentStreak}
                                onLogout={handleLogout}
                            />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/goals"
                    element={
                        <ProtectedRoute>
                            <GoalList />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <ProtectedRoute>
                            <Profile onProfileUpdate={handleProfileUpdate} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/complete-profile"
                    element={
                        <ProtectedRoute>
                            <CompleteProfile onProfileUpdate={handleProfileUpdate} />
                        </ProtectedRoute>
                    }
                />

                {/* Routes accessible without login */}
                <Route path="/api/shared-progress/:share_id" element={<SharedProgress />} />
                <Route path="/verify-email/:token" element={<VerifyEmail />} />
                <Route path="/reset-password" element={<ResetPassword />} />

                {/* 404 catch-all route */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </TransitionWrapper>
    );
};

export default App;
