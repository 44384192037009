import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState('Verifying...');
    const isVerified = useRef(false);

    useEffect(() => {
        if (isVerified.current) {
            return;
        }

        const verifyEmail = async () => {
            try {
                console.log('Sending verification request...');
                const response = await axios.get(`https://goaltracker-92af4b515b01.herokuapp.com/auth/verify-email/${token}`);
                console.log('Verification response:', response);
                if (response.status === 200) {
                    setStatus('Email verified successfully. Redirecting to login...');
                    setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
                }
            } catch (error) {
                console.error('Verification error:', error);
                setStatus('Invalid or expired token. Redirecting to register...');
                setTimeout(() => navigate('/register'), 3000); // Redirect to register after 3 seconds
            }
        };

        verifyEmail();
        isVerified.current = true;
    }, [token, navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 font-poppins">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
                <h1 className="text-2xl mb-4 font-semibold">Email Verification</h1>
                <p>{status}</p>
            </div>
        </div>
    );
};

export default VerifyEmail;
