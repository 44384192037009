import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import api from '../api'; // Import the api module
import './Login.css'; // Assuming you want to reuse the same CSS

const ResetPassword = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('token');


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            enqueueSnackbar('Passwords do not match!', { variant: 'error' });
            return;
        }

        try {
            const response = await api.post('https://goaltracker-92af4b515b01.herokuapp.com/auth/reset-password', { token, new_password: newPassword });
            enqueueSnackbar(response.data.message || 'Password has been reset successfully!', { variant: 'success' });
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error) {
            enqueueSnackbar(error.response?.data?.error || 'Failed to reset password!', { variant: 'error' });
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 font-poppins relative">
            <div className="absolute inset-0 flex justify-center items-center">
                <div className="absolute bg-blue-300 rounded-full h-96 w-96 -top-20 -left-40 opacity-30"></div>
                <div className="absolute bg-green-300 rounded-full h-80 w-80 -bottom-20 -right-40 opacity-30"></div>
            </div>
            <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md z-10">
                <h1 className="text-2xl mb-6 text-center font-semibold">Reset Password</h1>
                <div className="mb-4">
                    <label className="block mb-2 text-gray-600" htmlFor="newPassword">New Password</label>
                    <div className="flex items-center border border-gray-300 p-2 rounded">
                        <i className="fas fa-lock mr-2 text-gray-500"></i>
                        <input
                            id="newPassword"
                            type="password"
                            placeholder="New Password"
                            className="w-full focus:outline-none smooth-text"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block mb-2 text-gray-600" htmlFor="confirmPassword">Confirm Password</label>
                    <div className="flex items-center border border-gray-300 p-2 rounded">
                        <i className="fas fa-lock mr-2 text-gray-500"></i>
                        <input
                            id="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                            className="w-full focus:outline-none smooth-text"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                </div>
                <button type="submit" className="bg-blue-500 text-white py-3 px-6 rounded-full w-full font-semibold flex items-center justify-center">
                    <i className="fas fa-sign-in-alt mr-2"></i> Reset Password
                </button>
            </form>
        </div>
    );
};

export default ResetPassword;
