import React, { useState, useEffect } from 'react';
import { FaCrown, FaMedal, FaRegSadCry, FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import api from '../api'; // Assuming you are fetching leaderboard data from an API

const Leaderboard = () => {
    const [leaderboard, setLeaderboard] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state

    const fetchLeaderboard = async () => {
        try {
            const response = await api.get('/api/leaderboard/public'); // Fetch leaderboard data
            setLeaderboard(response.data);
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
        } finally {
            setLoading(false); // Stop loading after fetching
        }
    };

    useEffect(() => {
        fetchLeaderboard();
    }, []);

    return (
        <div className="p-4 bg-white rounded-lg shadow-lg font-poppins">
            <div className="flex items-center mb-4 justify-between">
                <h2 className="text-2xl font-semibold flex items-center">
                    <FaCrown className="mr-2 text-yellow-500" /> Leaderboard
                </h2>
                <div className="relative flex items-center">
                    <FaInfoCircle 
                        className="text-gray-500 cursor-pointer" 
                        data-tooltip-id="infoTooltip"
                    />
                    <Tooltip id="infoTooltip" place="top" type="dark" effect="solid">
                        To land on the leaderboard, maintain the highest streak of completed goals.
                    </Tooltip>
                </div>
            </div>

            {loading ? (
                <div className="flex justify-center items-center min-h-[200px]">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v8z"
                        ></path>
                    </svg>
                </div>
            ) : leaderboard.length === 0 ? (
                <div className="flex flex-col items-center">
                    <FaRegSadCry className="text-gray-400 text-6xl mb-4" />
                    <p className="text-gray-600 text-center">
                        No users on the leaderboard yet. Keep working on your goals!
                    </p>
                </div>
            ) : (
                <ul>
                    {leaderboard.map((user, index) => (
                        <li key={user.id} className="mb-2 flex items-center">
                            <span className="mr-2 text-xl">{index + 1}.</span>
                            <span className="mr-2 text-blue-500">{user.name}</span>
                            <span className="ml-auto text-gray-600">{user.streak} days</span>
                            {index === 0 && <FaCrown className="ml-2 text-yellow-500" />}
                            {index === 1 && <FaMedal className="ml-2 text-gray-400" />}
                            {index === 2 && <FaMedal className="ml-2 text-orange-500" />}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Leaderboard;
