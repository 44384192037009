import React, { useState, useEffect } from 'react';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import api from '../api';

const PublicGoalList = () => {
    const [goals, setGoals] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(null); // Track errors

    const fetchPublicGoals = async () => {
        try {
            const response = await api.get('/api/goals/public');
            setGoals(response.data);
        } catch (err) {
            console.error('Error fetching public goals:', err);
            setError('Unable to load goals. Please try again later.');
        } finally {
            setLoading(false); // Stop loading after fetching
        }
    };

    useEffect(() => {
        fetchPublicGoals();
    }, []);

    const parseDate = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? 'Invalid Date' : date;
    };

    const calculateCurrentDay = (startDate) => {
        const start = parseDate(startDate);
        if (typeof start === 'string') return start;

        const now = new Date();
        const diffTime = Math.abs(now - start);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const calculateCompletionPercentage = (goal) => {
        const totalDays = goal.progress.length;
        const completedDays = goal.progress.filter((day) => day.completed).length;
        return totalDays === 0 ? 0 : Math.round((completedDays / totalDays) * 100);
    };

    return (
        <div className="min-h-12 p-4 bg-gray-100 font-poppins">
            {loading ? (
                <div className="flex justify-center items-center min-h-[50vh]">
                    <svg
                        className="animate-spin h-10 w-10 text-blue-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        role="status"
                        aria-label="Loading public goals"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v8z"
                        ></path>
                    </svg>
                </div>
            ) : error ? (
                <div className="flex justify-center items-center min-h-[50vh] text-red-500">
                    <p>{error}</p>
                </div>
            ) : (
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
                    {goals.length === 0 ? (
                        <p className="text-center text-gray-500">
                            No public goals found. Be the first to share a goal!
                        </p>
                    ) : (
                        goals.map((goal) => {
                            const currentDay = calculateCurrentDay(goal.progress[0]?.date);
                            const completionPercentage = calculateCompletionPercentage(goal);

                            return (
                                <div
                                    key={goal._id}
                                    className="bg-white p-6 rounded-lg shadow-lg flex flex-col relative"
                                >
                                    <div className="flex items-center mb-4">
                                        <CalendarDaysIcon className="h-6 w-6 text-blue-500 mr-2" />
                                        <h2 className="text-2xl font-bold">{goal.title}</h2>
                                    </div>
                                    <p className="text-gray-600">{goal.duration} days</p>
                                    <p className="text-gray-600">
                                        Start Date:{' '}
                                        {parseDate(goal.progress[0]?.date) instanceof Date
                                            ? parseDate(goal.progress[0]?.date).toLocaleDateString()
                                            : parseDate(goal.progress[0]?.date)}
                                    </p>
                                    <p className="text-blue-600 font-semibold">Current Day: {currentDay}</p>
                                    <p className="text-green-600 font-semibold">
                                        Completion: {completionPercentage}%
                                    </p>
                                    <div className="mt-4 flex-grow">
                                        <h3 className="text-xl font-semibold mb-2">Progress</h3>
                                        <div className="grid grid-cols-7 gap-2">
                                            {goal.progress.map((day, index) => (
                                                <div
                                                    key={index}
                                                    className={`px-2 py-1 rounded text-xs ${
                                                        day.completed
                                                            ? 'bg-green-200'
                                                            : 'bg-gray-200'
                                                    } text-gray-600`}
                                                >
                                                    {index + 1}: {day.completed ? '✅' : '❌'}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            )}
        </div>
    );
};

export default PublicGoalList;
