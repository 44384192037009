// src/components/EditGoalForm.js
import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { PencilIcon } from '@heroicons/react/24/solid';
import api from '../api'; // Import the api module

const EditGoalForm = ({ isOpen, onClose, goal, setGoals, refreshGoals }) => {
    const [title, setTitle] = useState(goal?.title || '');

    useEffect(() => {
        if (goal) {
            setTitle(goal.title);
        }
    }, [goal]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedGoal = { title };

        try {
            const response = await api.put(`/api/goals/${goal._id}`, updatedGoal);
            if (response.status === 200) {
                toast.success('Goal updated successfully!');
                setGoals((prevGoals) =>
                    prevGoals.map((g) => (g._id === goal._id ? { ...g, ...updatedGoal } : g))
                );
                if (refreshGoals) refreshGoals();
                onClose();
            } else {
                toast.error('Failed to update goal');
            }
        } catch (error) {
            toast.error('Error updating goal');
        }
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between items-center">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Edit Goal
                                    </Dialog.Title>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={onClose}
                                    >
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="mt-2">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4">
                                            <label className="block mb-2 text-gray-600" htmlFor="title">Title</label>
                                            <input
                                                id="title"
                                                type="text"
                                                placeholder="Goal Title"
                                                className="w-full border border-gray-300 p-2 rounded focus:outline-none"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-full w-full font-semibold flex items-center justify-center">
                                            <PencilIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                            Update Goal
                                        </button>
                                    </form>
                                </div>
                                <ToastContainer />
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EditGoalForm;
