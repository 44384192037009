import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Card, CardContent, Typography, Box, Button } from '@mui/material';
import { People, Assignment, Timeline, ExitToApp } from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom';
import api from '../../api'; // Adjust the import path based on your project structure
import './AdminDashboard.css'; // Import custom CSS for additional styles

const AdminDashboard = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [stats, setStats] = useState({
        total_users: 0,
        daily_users: 0,
        total_goals: 0,
        goals_per_user: {},
        active_users_over_time: [],
        popular_goal_types: [],
        average_goal_duration: 0,
        recent_user_activity: [],
        geographical_distribution: []
    });

    const fetchStats = async () => {
        try {
            const response = await api.get('/admin/admin/stats');
            setStats(response.data);
        } catch (error) {
            enqueueSnackbar('Failed to fetch statistics', { variant: 'error' });
        }
    };

    useEffect(() => {
        fetchStats();
        const interval = setInterval(fetchStats, 300000); // Refresh every 5 minutes
        return () => clearInterval(interval); // Cleanup on unmount
    }, [enqueueSnackbar]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
        window.location.reload(); // Force a reload of the login page
    };

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4" gutterBottom>
                    Admin Dashboard
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleLogout}
                    startIcon={<ExitToApp />}
                >
                    Logout
                </Button>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card className="live-card">
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <People fontSize="large" style={{ color: '#4caf50' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">
                                        Total Users
                                    </Typography>
                                    <Typography variant="h4">
                                        {stats.total_users} <span className="dot"></span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card className="live-card">
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Assignment fontSize="large" style={{ color: '#2196f3' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">
                                        Daily Active Users
                                    </Typography>
                                    <Typography variant="h4">
                                        {stats.daily_users} <span className="dot"></span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card className="live-card">
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Assignment fontSize="large" style={{ color: '#ff9800' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">
                                        Total Goals
                                    </Typography>
                                    <Typography variant="h4">
                                        {stats.total_goals} <span className="dot"></span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card className="live-card">
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Timeline fontSize="large" style={{ color: '#f44336' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">
                                        Average Goal Duration
                                    </Typography>
                                    <Typography variant="h4">
                                        {stats.average_goal_duration.toFixed(2)} days <span className="dot"></span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Box mt={5}>
                <Typography variant="h6" gutterBottom>
                    Active Users Over Time
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                        data={stats.active_users_over_time}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tickFormatter={tick => tick || ''} />
                        <YAxis tickFormatter={tick => tick || ''} />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="count" stroke="#82ca9d" />
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default AdminDashboard;