import { Fragment, useState } from 'react';
import { Disclosure, Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, PlusCircleIcon, UserIcon } from '@heroicons/react/24/outline';
import { SparklesIcon, HeartIcon } from '@heroicons/react/24/solid';
import { useNavigate, Link } from 'react-router-dom';

const navigation = [{ name: 'Home', href: '/', current: true }];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const PublicHeader = () => {
    const navigate = useNavigate();
    const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);

    const openAboutUs = () => setIsAboutUsOpen(true);
    const closeAboutUs = () => setIsAboutUsOpen(false);

    return (
        <>
            <header className="sticky top-0 z-50 bg-gray-800 shadow-md">
                <Disclosure as="nav">
                    {({ open }) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="relative flex h-16 items-center justify-between">
                                    <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                                        <Disclosure.Button
                                            className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            aria-label="Open main menu"
                                        >
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>

                                    <div className="flex flex-1 items-center justify-center">
                                        <Link to="/" aria-label="Goal Tracker - Home">
                                            <img
                                                className="h-8 w-auto"
                                                src="/G.png"
                                                alt="Goal Tracker Logo"
                                            />
                                        </Link>
                                        <nav className="hidden lg:ml-6 lg:flex lg:space-x-8">
                                            <Link
                                                to="/"
                                                className={classNames(
                                                    'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-sm font-medium'
                                                )}
                                            >
                                                Home
                                            </Link>
                                            <button
                                                onClick={openAboutUs}
                                                className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                                            >
                                                About Us
                                            </button>
                                        </nav>
                                    </div>

                                    <div className="flex items-center space-x-4">
                                        <button
                                            onClick={() => navigate('/login')}
                                            className="bg-blue-600 hover:bg-blue-700 text-white rounded-md px-3 py-2 text-sm font-medium flex items-center"
                                        >
                                            <UserIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                            Login
                                        </button>

                                        <button
                                            onClick={() => navigate('/login')}
                                            className="bg-blue-500 hover:bg-blue-700 text-white rounded-md px-3 py-2 text-sm font-medium flex items-center"
                                        >
                                            <PlusCircleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                                            Create a Goal
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="lg:hidden">
                                <div className="space-y-1 px-2 pt-2 pb-3">
                                    <Link
                                        to="/"
                                        className={classNames(
                                            'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block rounded-md px-3 py-2 text-base font-medium'
                                        )}
                                    >
                                        Home
                                    </Link>
                                    <button
                                        onClick={openAboutUs}
                                        className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                                    >
                                        About Us
                                    </button>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </header>

            <Transition appear show={isAboutUsOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeAboutUs}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-50 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-8 text-left align-middle shadow-xl transition-all">
                                    <div className="flex items-center mb-4">
                                        <SparklesIcon className="h-6 w-6 text-blue-500 mr-2" />
                                        <Dialog.Title
                                            as="h3"
                                            className="text-xl font-semibold text-gray-900"
                                        >
                                            About Us
                                        </Dialog.Title>
                                    </div>

                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500 leading-6">
                                            This tool was created to track personal goals and stay accountable.
                                            Over time, it evolved into a platform to empower others to achieve their ambitions. 
                                            Our mission is to help individuals grow, support each other, and unlock their true potential.
                                        </p>

                                        <div className="mt-4 flex items-center">
                                            <HeartIcon className="h-5 w-5 text-red-500 mr-2" />
                                            <p className="text-sm text-gray-500">
                                                New features and improvements are coming soon. 
                                                Thank you for being part of our journey!
                                            </p>
                                        </div>

                                        <p className="text-right mt-4 text-sm font-semibold text-gray-700">
                                            - The GoalTracker Team
                                        </p>
                                    </div>

                                    <div className="mt-6 flex justify-end">
                                        <button
                                            type="button"
                                            className="bg-blue-600 hover:bg-blue-700 text-white rounded-md px-4 py-2 text-sm font-medium"
                                            onClick={closeAboutUs}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default PublicHeader;
