import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaLightbulb, FaCalendarAlt, FaClock, FaCheckCircle, FaListAlt, FaStar, FaHeart, FaFlag } from 'react-icons/fa';
import api from '../api'; // Import the api module

const icons = [FaListAlt, FaStar, FaHeart, FaFlag, FaCheckCircle];

const getRandomIcon = () => {
    return icons[Math.floor(Math.random() * icons.length)];
};

const OtherGoalsList = () => {
    const [otherGoals, setOtherGoals] = useState([]);

    useEffect(() => {
        const fetchOtherGoals = async () => {
            try {
                const response = await api.get('/api/goals/others');
                setOtherGoals(response.data);
            } catch (error) {
                toast.error('Failed to fetch other users\' goals');
            }
        };

        fetchOtherGoals();
    }, []);

    return (
        <div className="p-4 bg-gray-100 font-poppins">
            <h3 className="text-4xl font-semibold mb-4 flex items-center">
                <FaLightbulb className="mr-2 text-yellow-500" /> Get Inspired by Others
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                {otherGoals.length === 0 ? (
                    <p>No goals found</p>
                ) : (
                    otherGoals.map((goal) => {
                        const IconComponent = getRandomIcon();
                        return (
                            <div key={goal._id} className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between">
                                <h2 className="text-2xl font-bold mb-2 flex items-center">
                                    <IconComponent className="mr-2 text-blue-500" /> {goal.title}
                                </h2>
                                <div className="flex items-center text-gray-600 mb-4">
                                    <FaClock className="mr-2" /> {goal.duration} days
                                </div>
                                <div className="flex items-center text-gray-600 mb-4">
                                    <FaCalendarAlt className="mr-2" /> {goal.specific_days && goal.specific_days.length > 0 ? 'Specific Days' : 'Everyday'}
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default OtherGoalsList;
