// src/components/Section.js
import React from 'react';

const Section = ({ title, children, className }) => {
    return (
        <section className={`p-4 bg-white rounded-lg shadow-md mb-4 ${className}`}>
            <h2 className="text-2xl font-bold mb-4">{title}</h2>
            {children}
        </section>
    );
};

export default Section;
