import React, { useState, useEffect } from 'react';
import { FaThumbsUp, FaPaperPlane, FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import api from '../api';

const BeforeAfter = ({ isAuthenticated, user }) => {
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [comment, setComment] = useState('');
    const [hasLiked, setHasLiked] = useState(false);
    const [showAllComments, setShowAllComments] = useState(false);
    const navigate = useNavigate();

    const fetchLatestPost = async () => {
        try {
            const response = await api.get('api/featured_posts/latest');
            const postData = response.data;
            setPost({
                ...postData,
                liked_usernames: postData.liked_usernames || []
            });
            setHasLiked(postData.liked_usernames.includes(user?.email));
        } catch (error) {
            console.error("Error fetching post data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleLike = async () => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        try {
            const response = await api.post('api/featured_posts/latest/like');
            const message = response.data.message;

            setPost((prevPost) => ({
                ...prevPost,
                likes: message === "Post liked successfully" ? prevPost.likes + 1 : prevPost.likes - 1,
                liked_usernames: message === "Post liked successfully" 
                    ? [...(prevPost.liked_usernames || []), user?.email] 
                    : prevPost.liked_usernames.filter((email) => email !== user?.email)
            }));
            setHasLiked(!hasLiked);
        } catch (error) {
            console.error("Error toggling like:", error);
        }
    };

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        if (comment.trim()) {
            try {
                await api.post('api/featured_posts/latest/comment', { comment_text: comment });
                const newComment = {
                    username: user?.username || user?.email.split('@')[0],
                    text: comment,
                    timestamp: new Date().toISOString(),
                };
                setPost((prevPost) => ({
                    ...prevPost,
                    comments: [...(prevPost.comments || []), newComment],
                }));
                setComment('');
            } catch (error) {
                console.error("Error submitting comment:", error);
            }
        }
    };

    useEffect(() => {
        fetchLatestPost();
    }, []);

    if (loading) return (
        <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
        </div>
    );
    if (!post) return <div>Error: No featured post found</div>;

    // Determine the comments to display
    const commentsToDisplay = showAllComments ? post.comments : post.comments.slice(0, 3);

    return (
        <div className="flex flex-col items-center my-6 p-4 bg-white shadow-md rounded-lg border border-gray-200 max-w-md">
            <div className="self-start bg-yellow-400 text-white text-xs font-bold px-2 py-1 rounded mb-2">
                Featured Achievement
            </div>

            <h3 className="text-lg font-bold text-blue-600 mb-2 text-center">{post.title}</h3>
            <p className="text-gray-600 text-center text-sm mb-4">
                See the amazing transformations our users have achieved!
            </p>
            <div className="relative w-full">
                <img src={post.image_path} alt="Before and After" className="w-full rounded-lg shadow-lg" />
                <div className="absolute top-2 left-2 bg-blue-500 text-white text-xs px-2 py-1 rounded">Before</div>
                <div className="absolute top-2 right-2 bg-green-500 text-white text-xs px-2 py-1 rounded">After</div>
            </div>

            <div className="mt-3 flex items-center space-x-2">
                <button 
                    onClick={handleLike}
                    className="flex items-center text-blue-500 hover:text-blue-600 focus:outline-none"
                    aria-label="Like this transformation"
                >
                    <FaThumbsUp className="mr-1" />
                    <span className="font-semibold">{post.likes}</span>
                </button>
                <span className="text-gray-500 text-xs">Likes</span>
            </div>

            <div className="mt-4 text-center">
                <p className="text-gray-700 text-sm mb-2">
                    Start your own journey today!
                </p>
            </div>

            <div className="mt-4 w-full">
                <div className="flex items-center justify-between mb-2">
                    <h4 className="text-md font-semibold text-gray-700">Comments</h4>
                    <span className="text-gray-500 text-xs">{post.comments.length} Comments</span>
                </div>

                <form onSubmit={handleCommentSubmit} className="relative mb-2">
                    <input 
                        type="text" 
                        placeholder="Add a comment..."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="w-full px-3 py-1 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 text-sm"
                    />
                    <button 
                        type="submit"
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600 focus:outline-none"
                        aria-label="Send comment"
                    >
                        <FaPaperPlane size={16} />
                    </button>
                </form>

                <ul className="mt-2 space-y-1">
                    {commentsToDisplay.map((cmt, index) => (
                        <li key={index} className="flex items-start space-x-2 text-gray-800 bg-gray-100 p-2 rounded-lg shadow-sm text-sm">
                            <FaUserCircle className="text-blue-500 text-lg" />
                            <div>
                                <div className="flex items-center space-x-1">
                                    <span className="font-semibold">{cmt.username}</span>
                                </div>
                                <p className="text-gray-700">{cmt.text}</p>
                            </div>
                        </li>
                    ))}
                </ul>

                {/* Show 'Show all comments' or 'Show less' based on state */}
                {post.comments.length > 3 && (
                    <button 
                        onClick={() => setShowAllComments(!showAllComments)}
                        className="mt-2 text-blue-500 hover:text-blue-600 text-sm focus:outline-none"
                    >
                        {showAllComments ? 'Show less' : 'Show all comments'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default BeforeAfter;
